<template>
  <div class="container">
    <img class="startnow" src="/img/startnow.svg" alt="start now ico">
    <h1>Darmowy i Łatwy Kalkulator Online</h1>
    <p>Masz dość skomplikowanych obliczeń? Chcesz szybko i łatwo przeliczyć procenty? Nasz darmowy kalkulator online jest idealnym rozwiązaniem dla Ciebie!</p>
  </div>
</template>

<script>
export default {
  name: 'StartView',
}
</script>

<style scoped>
  .container {
    background-color: var(--bg_main);
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;

    background: rgb(34,193,195);
    background: linear-gradient(180deg, var(--bg_main) 0%, var(--bar) 100%);
    color: var(--font);
  }

  h1 {
    font-size: 30px;
    line-height: normal;
    margin: 0;
    padding-bottom: 20px;

    background: linear-gradient(90deg, #82f369 0%, #91cfff 50%, #ffaacc 100%);
    color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    text-fill-color: transparent;
  }

  p {
    margin: 0;
    padding: 0 20% 0 20%;
  }

  .startnow {
    width: 50%;
    padding-right: 20px;
    filter: invert(var(--invert));
    align-self: flex-end;
  }

  @media only screen and (min-width: 768px) {
    .startnow {
      width: 15%;
    }
  }
</style>