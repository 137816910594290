import { createRouter, createWebHistory } from 'vue-router'
import StartView from '../views/StartView.vue'
import HomeView from '../views/Percents/PercentageOfNumber.vue'
import AboutView from '@/views/Percents/GrowthPercentage.vue'
import AddPercent from '@/views/Percents/AddPercent.vue'
import SubtractPercent from '@/views/Percents/SubtractPercent.vue'
import XfromY from '@/views/Percents/XfromY.vue'
import PxToRem from '@/views/Web/PxToRem.vue'
import RemToPx from '@/views/Web/RemToPx.vue'
import Hydro from '@/views/Cooking/Hydro.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: StartView
  },
  {
    path: '/oblicz-procent-liczby',
    name: 'HomeView',
    component: HomeView
  },
  {
    path: '/oblicz-wzrost-procentowy',
    name: 'AboutView',
    component: AboutView
  },
  {
    path: '/dodaj-procent-liczby',
    name: 'AddPercent',
    component: AddPercent
  },
  {
    path: '/odejmij-procent-liczby',
    name: 'SubtractPercent',
    component: SubtractPercent
  },
  {
    path: '/jakim-procentem-liczby-X-jest-Y',
    name: 'XfromY',
    component: XfromY
  },
  {
    path: '/px-do-rem',
    name: 'PxToRem',
    component: PxToRem
  },
  {
    path: '/rem-do-px',
    name: 'RemToPx',
    component: RemToPx
  },
  {
    path: '/hydratacja-ciasta',
    name: 'Hydro',
    component: Hydro
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
