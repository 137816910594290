<template>
  <div class="Holder">
    <div class="MenuHolder">
      <h1>Policzy.li</h1>
      <div class="centerman">
        <img @click="SwitchUi()" src="./assets/menu.svg" alt="light" class="Menu_img">
        <button @click="OpenMenu()" class="Menu" name="Menu">Menu</button>
      </div>
    </div>
    
    <div class="MenuContainer">
      <div @click="CloseMenu()" class="B"></div>
      <div class="A">
        <router-link to="/" @click="CloseMenu()">Start</router-link>
        <label>Kuchnia</label>
        <router-link to="/hydratacja-ciasta" @click="CloseMenu()">Oblicz hydratacje ciasta</router-link>
        <label>CSS webdev</label>
        <router-link to="/px-do-rem" @click="CloseMenu()">Przelicz piksele do REM</router-link>
        <router-link to="/rem-do-px" @click="CloseMenu()">Przelicz REM do pikseli</router-link>
        <label>% Obliczanie procentów</label>
        <router-link to="/oblicz-procent-liczby" @click="CloseMenu()">Oblicz procent z liczby</router-link>
        <router-link to="/oblicz-wzrost-procentowy" @click="CloseMenu()">O ile procent wzrosło / zmalało</router-link>
        <router-link to="/dodaj-procent-liczby" @click="CloseMenu()">Dodaj procent do liczby</router-link>
        <router-link to="/odejmij-procent-liczby" @click="CloseMenu()">Odejmij procent od liczby</router-link>
        <router-link to="/jakim-procentem-liczby-X-jest-Y" @click="CloseMenu()">Oblicz jakim procentem liczby x jest liczba y</router-link>
      </div>
    </div>

    <router-view class="RouterView"/>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  data() {
    return {
      uimode: false,
    }
  },
  methods: {
    OpenMenu() {
      const collection = document.getElementsByClassName("MenuContainer");
      collection[0].style = "display: flex";
    },
    CloseMenu() {
      const collection = document.getElementsByClassName("MenuContainer");
      collection[0].style = "display: none";
    },
    SwitchUi() {
      let root = document.querySelector(':root');
      if (!this.uimode) {
        root.style.setProperty('--main', '#A9C1D1');
        root.style.setProperty('--main_h', 'black');
        root.style.setProperty('--main_cont', 'white');
        root.style.setProperty('--bg_main', '#22262A');
        root.style.setProperty('--bar', '#3d434a');
        root.style.setProperty('--bar_bt', '#23323D');
        root.style.setProperty('--inpt', '#333544');
        root.style.setProperty('--inpt_b', '#23323D');
        root.style.setProperty('--inpt_c', '#ffff');
        root.style.setProperty('--menu_bg', '#333544');
        root.style.setProperty('--font', '#f2f2f2');
        root.style.setProperty('--invert', '1');
        this.uimode = !this.uimode;
      } else {
        root.style.setProperty('--main', '#145B8A');
        root.style.setProperty('--main_h', '#2c3e50');
        root.style.setProperty('--main_cont', 'black');
        root.style.setProperty('--bg_main', '#e8e8e8');
        root.style.setProperty('--bar', '#ffff');
        root.style.setProperty('--bar_bt', '#ededed');
        root.style.setProperty('--inpt', '#ffff');
        root.style.setProperty('--inpt_b', '#dadada');
        root.style.setProperty('--inpt_c', 'black');
        root.style.setProperty('--menu_bg', '#f2f2f2');
        root.style.setProperty('--font', '#1c1c1c');
        root.style.setProperty('--invert', '0');
        this.uimode = !this.uimode;
      }
    },
    AutoUi () {
      if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        // dark mode
        this.uimode = false;
      } else {
        // light mode
        this.uimode = true;
      }
      this.SwitchUi();
    },
    UiObserver () {
      window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', event => {
          this.uimode = event.matches ? false : true;
          this.SwitchUi();
      });
    }
  },
  created() {
  	this.AutoUi();
    this.UiObserver();
  },
}
</script>

<style>
:root {
  --main: #145B8A;
  --main_h: #2c3e50;
  --main_cont: #000000; 
  --bg_main: #e8e8e8;
  --bar: #ffff;
  --bar_bt: #ededed;
  --inpt: #ffff;
  --inpt_b: #dadada;
  --inpt_c: black;
  --menu_bg: #f2f2f2;
  --font: #1c1c1c;
  --invert: 0;
}

body {
  margin: 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

.Menu {
  background-color: var(--main);
  border-radius: 10px;
  border: none;
  color: var(--bar);
  font-size: 14px;
  margin: 0;
  padding: 10px 20px 10px 20px;
  height: 100%;
}

.centerman {
  display: flex;
  align-items: center;
  gap: 20px;
}

.Menu_img {
  width: 24px;
  height: 24px;
  cursor: pointer;
  filter: invert(var(--invert));
}

.Menu:hover {
  background-color: var(--main_h);
  cursor: pointer;
}

.MenuContainer {
  position: fixed;
  height: 100dvh;
  width: 100dvw;
  display: none;
  z-index: 10;
}

.MenuContainer .A {
  display: flex;
  overflow-y: auto;
  flex-direction: column;
  flex-grow: 1;
  background-color: var(--menu_bg);
  padding: 10px;
}

.MenuContainer .B {
  flex-grow: 3;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(20px);
}

.MenuContainer label {
  border-top: 1px solid #e3e3e3;
  color: #909090;
  margin-top: 10px;
  padding: 10px;
  font-size: 14px;
}

.MenuContainer a {
  font-size: 16px;
  text-decoration: none;
  color: var(--main_cont);
  padding: 10px;
}

.MenuContainer a:hover {
  background-color: #d6d6d6;
  border-radius: 10px;
}

.MenuHolder {
  background-color: var(--bar);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  height: 40px;
  border-bottom: 1px solid var(--bar_bt);
}

.MenuHolder h1 {
  margin: 0;
  font-size: 18px;
  padding: 0;
  color: var(--main);
}

.MenuHolder img {
  height: fit-content;
}

.RouterView {
  flex-grow: 1;
  overflow-y: auto;
}

.Holder {
  display: flex;
  flex-direction: column;
  width: 100dvw;
  height: 100dvh;
}
</style>
