<template>
  <div class="container">
    <div class="calc">
      <div class="text">
        <h1>REM do PX</h1>
        <p>Przelicz wartości z REM do Pikseli</p>
      </div>

      <div class="inputHolder">
        <label>Domyślna wielkość tekstu (px):</label>
        <input id="inptX" placeholder="?" type="number">
      </div>

      <div class="inputHolder">
        <label>Rem:</label>
        <input id="inptY" placeholder="?" type="number">
      </div>

      <div class="inputHolder">
        <button @click="Calc()" name="Oblicz">Oblicz</button>
      </div>

      <div class="inputHolder">
        <input id="inptOut" placeholder="?" type="number" disabled>
        <label>px</label>
      </div>

      <p class="Copy" @click="CopyBasic()">Skopiuj</p>

      <div class="text">
        <h1>Odpowiedź tekstowa:</h1>
        <p id="textOut">{{ Y }}rem; to {{ Z }}px; gdy domyślna wielkość tekstu to {{  X }}px; </p>
      </div>
      <p class="Copy" @click="CopyText()">Skopiuj</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  data() {
    return {
      X: 0,
      Y: 0,
      Z: 0,
      
    }
  },
  methods: {
    Calc() {
      const X = document.getElementById("inptX").value;
      const Y = document.getElementById("inptY").value;

      document.getElementById("inptX").style = "background-color: transparent";
      document.getElementById("inptY").style = "background-color: transparent";

      if (!X || !Y) {
        if (!X) {
          document.getElementById("inptX").style = "background-color: #ffe6c9";
        }

        if (!Y) {
          document.getElementById("inptY").style = "background-color: #ffe6c9";
        }

      } else {
        document.getElementById("inptX").style = "background-color: transparent";

        const Score = parseFloat(Y) / (1 / X);
        document.getElementById("inptOut").value = Score;
        this.X = X;
        this.Y = Y;
        this.Z = Score;
      }
    },
    CopyBasic() {
      navigator.clipboard.writeText(this.Z);
    },
    CopyText () {
      const CopiedText = document.getElementById("textOut").innerHTML;
      navigator.clipboard.writeText(CopiedText);
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .container {
    background-color: var(--bg_main);
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .calc {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .inputHolder {
    display: flex;
    background-color: var(--inpt);
    border-radius: 10px;
    border: 1px solid var(--inpt_b);
    color: var(--inpt_c);
    margin: 10px;
    align-items: baseline;
  }

  .inputHolder label {
    padding: 10px;
    border-radius: 10px;
  }

  .inputHolder input {
    border: none;
    font-size: 16px;
    padding: 10px;
    margin: 5px;
    flex-grow: 1;
    border-radius: 10px;
    background-color: transparent;
    color: var(--inpt_c);
  }

  .inputHolder input:disabled {
    border-radius: 10px;
  }

  .inputHolder button {
    width: 100%;
    padding: 10px;
    border-radius: 10px;
    border: none;
    background-color: var(--main);
    color: white;
  }

  .inputHolder button:hover {
    background-color: var(--main_h);
    cursor: pointer;
  }

  h1 {
    font-size: 20px;
    margin: 0;
  }

  p {
    margin: 0;
  }

  .text {
    padding: 10px;
    background-color: #ddf0ff;
    border-radius: 10px;
    margin: 10px;
  }

  .Copy {
    text-decoration: underline;
    color: var(--main);
    align-self: flex-end;
    margin-right: 10px;
    cursor: pointer;
  }

  @media only screen and (min-width: 768px) {
    .container {
      align-items: center;
    }

    .calc {
      width: 70%;
    }
  }
</style>
